import { CheckoutStep } from './../modules/Ecom/gtmEvents'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'
import { CreditCheckResponse } from './CreditChecks'
import { ActionType, Upgrade } from './../modules/Catalog'
export interface UIConfig {
  env?: string
  uri?: string
  clientMetaTags?: string
  clientTitle?: string
  companyName: string
  gtmId?: GtmID
  reCaptchaKey?: string
  optimizeScriptTag?: string
  theme?: VuetifyThemeVariant
  benchmarkGaPropertyId?: string
  clientGaPropertyId?: string
  homeAutomationVideoLink?: string
  addressSearchUrl?: AddressSearchURL
  moverLink: string
  customerService: string
  costDuration?: string
  addressSearchPlaceholder?: string
  residentialLink: string
  businessLink: string
  logoLink?: string
  cartImage?: string
  confirmationImage?: string
  confirmationText?: string
  preSaleConfirmationText?: string
  addressSearchBackground?: string
  addressSearchBackgroundMobile?: string
  holidaysToDisable?: any[]
  disabledSchedulingDates?: any[]
  unlockAccountModalText?: string
  showUnlockAccountButton?: boolean
  salesLead?: SalesLeadUnion
  scheduling: SchedulingConfig
  'footer-links'?: FooterLink[] // deprecate kebob case
  footerLinks?: FooterLink[] // transition to camelCase
  autoComplete?: AutoComplete
  offers: Offer[]
  lobs: LobClass[]
  defaultLobs?: DefaultLobElement[]
  lobImages?: LobImage[]
  buyFlow: BuyFlow[]
  disclaimer: string[]
  email: Email
  debugPassword?: string[]
  editPaymentAmount?: boolean
  printImageTop?: boolean
  hidePrintConfirmationTitle?: boolean
  convergeLightBoxUrl?: string
  addressSearchText?: string
  headerLogo?: string
  student?: boolean
  bannerColor?: string
  footerColor?: string
  addressSeniority?: boolean
  showEstimatedFirstPayment?: boolean
  textAlerts?: string
  useConverge?: boolean
  creditCheck?: boolean // deprecated, remove once shouldCreditCheck is implemented
  shouldCreditCheck?: CreditCheckResponse
  creditApprovedSchedulingText?: string
  phonePortConsentText?: string
  phonePortConsentTitle?: string
  noBullets?: boolean
  MinTvs?: string
  showDob?: boolean
  ageLimit?: string
  enablePreSale?: boolean
  schedulingHeaderColor?: string
  prorationFunction?: string
  prorationCondition?: string
  prorationName?: string
  showTermsCheckbox?: boolean
  termsCheckboxLabel?: string
  transunionDisclaimer?: TransunionDisclaimer
  emailTemplate?: string
  cartHeaderColor?: string
  confirmation?: Confirmation
  termsOfService?: TermsOfService[]
  apiKey?: string
  internetNoOptionsMessage?: string
  customerServiceEmail?: string
  addressRule?: boolean
  defaultButtonColor?: string
  defaultButtonTextColor?: string
  showShopperNameInConfirmation?: boolean
  offerButtonText?: string
  monthlyTotalText?: string
  emailSubject?: string
  differentBillingAddress?: boolean
  abovePriceText?: string
  confirmationSubtitle?: string
  channelText?: string
  allowScheduling?: boolean
  installationOptionsHtml?: string
  installationDateHtml?: string
  installationDateOverride?: string
  textAreaLabel?: string
  serviceabilityQuestions?: ServiceabilityQuestion[]
  addressSearchHint?: string
  offerTile?: string
  steps?: Step[]
  showPinField?: boolean
  processCommercial?: boolean
  addressSearchBackgroundiPad?: string
  singlePackageText?: string
  bundlePackageText?: string
  hidePackagePriceZeroes?: boolean
  disableEmail?: boolean
  emailScheduleInfo?: string
  showPhoneProviderInfo?: boolean
  useAlternateBackgroundImage?: boolean
  joinAddressEntries?: boolean
  accountPanels: AccountPanel[]
  schedulingFields: AccountField[]
  authTokenTimeout?: number
  isSchedulingAutomated?: boolean
  preSaleDisableAutomation?: boolean
  delayAutomationConfirmationEmail?: boolean
  disableNonAutomatedConfirmationEmail?: boolean
  disableSuccessfulAutomationConfirmationEmail?: boolean
  disableFailedAutomationConfirmationEmail?: boolean
  phonePortingLoaText?: string
  comingSoon?: ComingSoon
  leadCapture?: LeadCapture
  creditCheckTypeFailureDisablesAutomation?: string
  creditCheckTypeNotPassedDisablesAutomation?: string
  estimatedMonthlyPaymentText?: string
  promoStepsAllowed?: number[]
  extraAutoCompleteParams?: string
  cartCloseIcon?: string
  cartCloseIconColor?: string
  findingOffersText?: string
  paymentConfig: PaymentConfig
  paymentOptionsConfirmationHeaderText?: string
  paymentOptionsConfirmationMessage?: string
  enablePayment?: boolean
  defaultScheduleValue?: string
  addressSearchInputColor?: string
  addressSearchButtonLabel?: string
  addressSearchButtonColor?: string
  customMasks?: MaskConfigObject[]
  errorPageHeader?: string
  errorPageText?: string
  socialMediaImages: SocialMediaFooterItem[]
  cardSharpCorners?: boolean
  packageUpgradePrefix: string
  packageUpgradePostfix: string
  packageUpgradeLessPrefix: string
  packageUpgradeLessPostfix: string
  addressSearchSubtitle: string
  underLobText: string
  upgradeButtonIcon: string
  upgradeButtonText: string
  dividersInCards: boolean
  concatenateConfirmationCardHeaderText: boolean
  showSeperateOrderId: boolean
  printButtonText: string
  useButtonsForLobs: boolean
  confirmationGreeting?: string
  confirmationAdditionalCustomerDetails?: { label: string; storeProp: string }[]
  presaleSubmitButtonText?: string
  hideSecondCalendar?: boolean
  firstCalendarText?: string
  secondCalendarText?: string
  confirmationTextConfig?: ConfirmationTextConfigItem[]
  showConfirmationHeaderText?: boolean
  debugTagConfig?: string[]
  addressSearchAuthRequired?: boolean
  addressSearchAuthToken?: string
  skipCustomization?: boolean
  broadbandConfigStringArray?: string[]
  headerPieces?: HeaderPiece[]
  logoInSteppers?: boolean
  displayCustomerPinInConfirmation?: boolean
  previousShopperApiConfig?: ApiConfig
  catalogApiConfig?: ApiConfig
  timeslotsApiConfig?: ApiConfig
  creditCheckApiConfig?: ApiConfig
  initializePaymentApiConfig?: ApiConfig
  finalizePaymentApiConfig?: ApiConfig
  submitOrderApiConfig?: ApiConfig
  captchaApiConfig?: ApiConfig
  uiValidClasses?: string[]
  displayPresaleCustomerServiceButton?: boolean
  agentAppLogo?: string
  externalAccountNumberConfirmationCartLabel?: string
  externalAccountNumberConfirmationCartMacro?: string
  displayExternalAccountNumberConfirmationCart?: boolean
  automatedSchedulingMessage?: string
  displayNewPhoneChoiceInConfirmationCart?: boolean
  displayKeepPhoneChoiceInConfirmationCart?: boolean
  newPhoneChoiceConfirmationCartMacro?: string
  keepPhoneChoiceConfirmationCartMacro?: string
  monthlyChargesCartLabelMacro?: string
  schedulingCardHeaderTextMacros?: Array<{ macroString: string; conditions: Conditional[] }>
  automatedMatchTypeRegex?: string
  preSaleDownPayment?: boolean
  preSaleRecurringPayment?: boolean
  delayCatalogForCaptcha?: boolean
  verticalThreeButtons?: boolean
  verticalButtonOrder?: string[]
  phoneCarrierDropdown?: AccountField
  payIncludePackageFees?: boolean
}

export interface ApiConfig {
  messages?: string[]
  messageTimeout?: number // in milliseconds -- time to wait before changing messages (next in array)
  pendingTimeout?: number // in milliseconds -- time back end should wait before return pending 202 the first time
  callTimeout?: number // in milliseconds -- time front end should wait before giving up on the call
  retryDelay?: number // in milliseconds -- how long to wait before retrying a 202
  retries?: number //this specifies the AXIOS retries, not the number of times to retry a 202
  skippable?: boolean // not yet implemented
  enable?: boolean // not yet implemented
  publicKey?: string // not yet implemented
}

interface HeaderPiece {
  type: 'image' | 'text' | 'address' | 'clickableImage' | 'clickableText' | 'icon' | 'spacer'
  image?: string
  text?: string
  class?: string
  link?: string
}

export interface PaymentConfig {
  payType: 'converge' | 'gocare' | 'none' | 'payintegration' | 'paytrace' | 'cardpointe' | 'stripe'
  autoPayRadioText?: string
  payRadioText?: string
  aboveIframeHtml?: string
  aboveIframeHtmlCC?: string
  aboveIframeHtmlACH?: string
  payIframeHtml?: string
  disclaimer?: string
  paymentFailedText?: string
  contactDetails?: string
  paymentButtonText?: string // deprecated, remove once shouldCreditCheck is implemented
  paymentModalButtonText?: string
  bothPaymentsModalText?: string
  downPaymentModalText?: string
  recurringPaymentModalText?: string
  downPaymentButtonText?: string
  recurringPaymentButtonText?: string
  bothPaymentsButtonText?: string
  downPaymentText?: string
  recurringPaymentText?: string
  bothPaymentsText?: string
  paymentDeclinedText?: string
  paymentApprovedText?: string
  bothPaymentsSuccessText?: string
  downPaymentSuccessText?: string
  recurringPaymentSuccessText?: string
  secondPaymentButtonText?: string
  allowAutoPay?: boolean
  customFields?: CustomField[]
  accountFields?: AccountField[]
  billingAccountFields?: AccountField[]
  closeButtonText?: string
  payIconHigh?: boolean
  payHeaderHtml?: string
  headerLogo?: string
  billingCheckbox?: boolean
  payAmount?: number
  disableErrorSubmit?: boolean
  customPaymentBlockers?: (Conditional | Conditional[])[]
  allowBypassPayment?: boolean
  allowBypassPaymentConditional?: (Conditional | Conditional[])[]
  skipCancelsAutomation?: boolean
  skipCancelsAutomationConditional?: (Conditional | Conditional[])[]
  addMonthlyChargesToDownPayment?: boolean
}

export interface CustomField {
  shopperVal: string
  replaceVariable: string
  default: string
}

const newVuetifyThemeVariant = (): VuetifyThemeVariant => {
  const theme: VuetifyThemeVariant = {
    primary: '#000000',
    secondary: '#000000',
    accent: '#000000',
    error: '#000000',
    warning: '#000000',
    info: '#000000',
    success: '#000000'
  }
  return theme
}

export const newUIConfig = (): UIConfig => ({
  env: 'local',
  clientMetaTags: '',
  clientTitle: undefined,
  companyName: '',
  gtmId: undefined,
  reCaptchaKey: '',
  theme: newVuetifyThemeVariant(),
  benchmarkGaPropertyId: '',
  clientGaPropertyId: '',
  homeAutomationVideoLink: '',
  addressSearchUrl: '',
  moverLink: '',
  customerService: '',
  costDuration: '',
  addressSearchPlaceholder: AddressSearchPlaceholder.EnterYourAddress,
  residentialLink: '',
  businessLink: '',
  logoLink: '',
  cartImage: '',
  confirmationImage: '',
  addressSearchBackground: '',
  addressSearchBackgroundMobile: '',
  holidaysToDisable: [],
  disabledSchedulingDates: [],
  unlockAccountModalText: '',
  showUnlockAccountButton: false,
  salesLead: [],
  scheduling: newScheduling(),
  'footer-links': [], // deprecate kebob case
  footerLinks: [], // transition to camelCase
  autoComplete: {
    color: '#ffffff' //can be a vuetify color string
  },
  offers: [],
  lobs: [],
  defaultLobs: [],
  lobImages: [],
  buyFlow: [],
  disclaimer: [],
  email: {
    bcc: []
  },
  debugPassword: [''],
  editPaymentAmount: false,
  printImageTop: false,
  hidePrintConfirmationTitle: false,
  convergeLightBoxUrl: '',
  addressSearchText: '',
  headerLogo: '',
  student: false,
  bannerColor: '',
  footerColor: '',
  addressSeniority: false,
  showEstimatedFirstPayment: false,
  textAlerts: '',
  useConverge: false,
  creditCheck: false,
  creditApprovedSchedulingText: '',
  phonePortConsentText: '',
  phonePortConsentTitle: '',
  noBullets: false,
  MinTvs: '',
  showDob: false,
  ageLimit: '',
  enablePreSale: false,
  schedulingHeaderColor: '',
  prorationFunction: '',
  prorationCondition: '',
  prorationName: '',
  showTermsCheckbox: false,
  termsCheckboxLabel: '',
  transunionDisclaimer: getTransunionDisclaimer(),
  emailTemplate: '',
  cartHeaderColor: '',
  confirmation: {
    paymentDueText: ''
  },
  termsOfService: [],
  apiKey: '',
  internetNoOptionsMessage: '',
  customerServiceEmail: '',
  addressRule: false,
  defaultButtonColor: '',
  defaultButtonTextColor: '',
  showShopperNameInConfirmation: false,
  offerButtonText: '',
  monthlyTotalText: '',
  emailSubject: '',
  differentBillingAddress: false,
  abovePriceText: '',
  confirmationSubtitle: '',
  channelText: '',
  allowScheduling: false,
  installationOptionsHtml: '',
  installationDateHtml: '',
  installationDateOverride: '',
  textAreaLabel: '',
  serviceabilityQuestions: [],
  addressSearchHint: '',
  offerTile: '',
  steps: [],
  showPinField: false,
  processCommercial: false,
  addressSearchBackgroundiPad: '',
  singlePackageText: '',
  bundlePackageText: '',
  hidePackagePriceZeroes: false,
  disableEmail: false,
  emailScheduleInfo: '',
  showPhoneProviderInfo: false,
  accountPanels: [{ title: '', fields: [] }],
  schedulingFields: [],
  preSaleDisableAutomation: false,
  comingSoon: {},
  leadCapture: { header1: '', confirmationHeader1: '', fields: [], isBackgroundDark: false, backButtonRoutePage: '' },
  creditCheckTypeFailureDisablesAutomation: '',
  creditCheckTypeNotPassedDisablesAutomation: '',
  estimatedMonthlyPaymentText: '',
  extraAutoCompleteParams: '',
  cartCloseIcon: '',
  cartCloseIconColor: '',
  paymentConfig: {
    payType: 'none',
    payIframeHtml: '',
    paymentModalButtonText: ''
  },
  paymentOptionsConfirmationHeaderText: '',
  paymentOptionsConfirmationMessage: '',
  enablePayment: false,
  customMasks: [],
  addressSearchButtonLabel: '',
  addressSearchInputColor: '',
  addressSearchButtonColor: '',
  socialMediaImages: [],
  cardSharpCorners: false,
  packageUpgradePrefix: '',
  packageUpgradePostfix: '',
  packageUpgradeLessPrefix: '',
  packageUpgradeLessPostfix: '',
  addressSearchSubtitle: '',
  underLobText: '',
  upgradeButtonIcon: '',
  upgradeButtonText: '',
  dividersInCards: false,
  concatenateConfirmationCardHeaderText: false,
  showSeperateOrderId: false,
  printButtonText: '',
  useButtonsForLobs: false,
  confirmationGreeting: '',
  confirmationAdditionalCustomerDetails: [],
  presaleSubmitButtonText: '',
  hideSecondCalendar: false,
  firstCalendarText: '',
  secondCalendarText: '',
  confirmationTextConfig: [],
  showConfirmationHeaderText: true,
  debugTagConfig: undefined,
  broadbandConfigStringArray: [],
  headerPieces: [],
  logoInSteppers: false,
  displayCustomerPinInConfirmation: true,
  previousShopperApiConfig: {},
  catalogApiConfig: {},
  timeslotsApiConfig: {},
  creditCheckApiConfig: {},
  initializePaymentApiConfig: {},
  finalizePaymentApiConfig: {},
  submitOrderApiConfig: {},
  captchaApiConfig: {},
  uiValidClasses: [],
  displayPresaleCustomerServiceButton: true,
  agentAppLogo: '',
  externalAccountNumberConfirmationCartLabel: '',
  externalAccountNumberConfirmationCartMacro: '',
  displayExternalAccountNumberConfirmationCart: true,
  displayNewPhoneChoiceInConfirmationCart: true,
  displayKeepPhoneChoiceInConfirmationCart: true,
  newPhoneChoiceConfirmationCartMacro: '',
  keepPhoneChoiceConfirmationCartMacro: '',
  monthlyChargesCartLabelMacro: '',
  schedulingCardHeaderTextMacros: []
})

export interface ComingSoon {
  title?: string
  subtitle?: string
  class?: string
  actions?: Action[]
}

export interface LeadCapture {
  name?: string
  header1: string
  header2?: string
  confirmationHeader1: string
  confirmationHeader2?: string
  image?: string
  confirmationImage?: string
  fields: AccountField[]
  additionalFields?: AccountField[]
  isBackgroundDark: boolean
  externalLink?: string
  buttonText?: string
  roundedSubmitButton?: boolean
  backButtonRoutePage: string
}

export interface Action {
  text?: string
  type?: string
  onClick?: ActionType
  class?: string
  color?: string
  bind?: any
}

export enum AddressSearchPlaceholder {
  EnterYourAddress = 'Enter your address',
  EnterYourHomeAddressIncludeYourStreetNumber = 'Enter your home address (include your street number)'
}

export type AddressSearchURL = string

export interface AutoComplete {
  color: Color | string
}

export enum Color {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryDark = 'secondaryDark',
  SecondaryDarkLighten3 = 'secondaryDark lighten-3',
  Tertiary = 'tertiary'
}

export interface BuyFlow {
  name: Name
  headerColor?: string
  backgroundImage?: string
  message1?: string[]
  hideStepper?: boolean
  message2?: string[]
  image?: string
}

export enum Name {
  AccountInfo = 'accountInfo',
  Common = 'common',
  Confirmation = 'confirmation',
  Noservice = 'noservice',
  Schedule = 'schedule',
  Serviceability = 'serviceability',
  Offers = 'offers',
  Installation = 'installation',
  Customize = 'customize',
  Account = 'account'
}

export interface Confirmation {
  paymentDueText: string
}

export enum DefaultLobElement {
  Internet = 'Internet',
  Phone = 'Phone',
  Tv = 'TV'
}

export interface Email {
  bcc: string[]
}

export interface FooterLink {
  link: string
  name: string
}

export enum GtmID {
  GtmKv635Gc = 'GTM-KV635GC',
  GtmP727Qrt = 'GTM-P727QRT'
}

export interface LobImage {
  lob: DefaultLobElement[]
  image?: string
}

export interface LobClass {
  headerColor: Color | string
}

interface Offer {
  headerColor: string
  image?: string
  buttonColor: string
  headerColor2?: string
}

export type SalesLeadUnion = any[] | SalesLeadClass

export interface SalesLeadClass {
  type: string
  salesLeadLink: string
  arguments?: Argument[]
  salesLeadTestAddress?: SalesLeadTestAddress
  salesLeadEnabled?: boolean
  salesLeadRedirectLink?: string
  salesLeadFallbackLink?: string
}

export interface Argument {
  name: string
  src: Src
  element: string
}

export enum Src {
  Address = 'ADDRESS',
  Shopper = 'SHOPPER',
  Tag = 'TAG'
}

export interface SalesLeadTestAddress {
  address: string
  zip: string
  service_class: number
}

export interface SchedulingConfig {
  type: 'scheduling'
  installMinDaysOut: number
  installMaxDaysOut: number
  installMinDaysOutPortedPhone?: number
  installMaxDaysOutPortedPhone?: number
  installMinDaysOutAutomated?: number
  installMaxDaysOutAutomated?: number
  installMinDaysOutAutomatedPortedPhone?: number
  installMaxDaysOutAutomatedPortedPhone?: number
  installMinDaysOutAutomatedEngineeringReview?: number
  installPostSroMinDaysOutAutomated?: number
  installPostSroMaxDaysOutAutomated?: number
  installSroMinDaysOutAutomated?: number
  installSroMaxDaysOutAutomated?: number
  unavailableDays: number[]
  automationIncludesUnavailableDays?: boolean
  billingSystemTimeSlots: BillingSystemTimeSlot[]
  defaultTimeSlots: DefaultTimeSlot[]
  automatedCustomDaysOutEnabled?: boolean
  automatedCustomDaysOutCutOffTime?: number
  automatedCustomDaysOutDaysOfWeek?: number[]
  automatedCustomDaysOutMinimumDays?: number
  automatedCustomDaysOutOverrideDays?: number
  batchSize?: number
  maxDateRange?: number
}

export const newScheduling = (): SchedulingConfig => ({
  type: 'scheduling',
  installMaxDaysOut: 10,
  installMinDaysOut: 0,
  installMinDaysOutAutomatedEngineeringReview: 0,
  automationIncludesUnavailableDays: false,
  automatedCustomDaysOutEnabled: false,
  automatedCustomDaysOutDaysOfWeek: [],
  automatedCustomDaysOutCutOffTime: 0,
  automatedCustomDaysOutMinimumDays: 0,
  automatedCustomDaysOutOverrideDays: 0,
  unavailableDays: [],
  billingSystemTimeSlots: [],
  defaultTimeSlots: [],
  batchSize: 30,
  maxDateRange: 90
})

export interface BillingSystemTimeSlot {
  value: string
  description: string
}

export interface DefaultTimeSlot {
  startTime: string
  endTime: string
  label: string
}

export enum EndTime {
  The1000 = '10:00',
  The1200 = '12:00',
  The1500 = '15:00',
  The1700 = '17:00'
}

export enum StartTime {
  The0800 = '08:00',
  The1000 = '10:00',
  The1300 = '13:00',
  The1500 = '15:00'
}

export interface ServiceabilityQuestion {
  question: string
  img: string
  headerColor?: string
  actions: Action[]
  cardButton?: boolean
}

export interface Action {
  name: string
  proceedToBuyFlow: boolean
  value: Value
  link?: string
}

export enum Value {
  ExistingLiving = 'existingLiving',
  ExistingMoving = 'existingMoving',
  NewMoving = 'newMoving'
}

export interface Step {
  title?: string
}

export interface TermsOfService {
  link: string
  text: string
}

export interface Theme {
  primary: string
  secondary: string
  tertiary?: string
  accent: string
  error: Error
  info: Info
  success: Success
  warning: Warning
  secondaryDark: string
  'offer-1'?: string
  'offer-2'?: string
  'offer-3'?: string
  'offer-4'?: string
  internet?: string
  tv?: string
  phone?: string
  homeAutomation?: string
  hargrayRed?: string
  button?: string
  package1?: string
  package2?: string
  package3?: string
  package4?: string
}

export enum Error {
  Ff0022 = '#ff0022',
  Ff5252 = '#FF5252',
  The05934A = '#05934a'
}

export enum Info {
  The00D0Ff = '#00d0ff',
  The05934A = '#05934a',
  The2196F3 = '#2196F3'
}

export enum Success {
  The05934A = '#05934a',
  The4Caf50 = '#4CAF50',
  The7Ac142 = '#7AC142'
}

export enum Warning {
  Ffc107 = '#FFC107',
  The05934A = '#05934a'
}

export interface TransunionDisclaimer {
  start: string
  link: string
  end: string
  modalTitle: string
  modalText: string
}
const getTransunionDisclaimer = (): TransunionDisclaimer => ({
  start: '',
  link: '',
  end: '',
  modalTitle: '',
  modalText: ''
})

export interface GtmConfig {
  onEnter?: GtmConfigEvent
  onLeave?: GtmConfigEvent
}

export interface GtmConfigEvent {
  virtualPage?: string
  step?: CheckoutStep
  stepOption?: string
}

export interface AccountPanel {
  title: string
  //virtualPage?: string
  gtm?: GtmConfig
  fields: AccountField[]
  validated?: boolean
}

export type AccountFieldType =
  | 'textField'
  | 'textArea'
  | 'checkbox'
  | 'terms'
  | 'date'
  | 'image'
  | 'text'
  | 'differentBilling'
  | 'addressSeniority'
  | 'questions'
  | 'referral'
  | 'schedule'
  | 'dropdown'
  | 'dropdownWithOther'
  | 'radioGroup'

export interface Conditional {
  element: string
  regexValue?: string
  field?: string
  value?: string
  type?: 'array' | 'string'
  matchType?: string
}

export type RuleType = 'required' | 'email' | 'phone' | string

export interface CustomRule {
  name: string
  regex: string
  errorMsg: string
  invalidate: boolean
}

export interface AccountField {
  label?: string
  secondaryLabel?: string
  secondaryFieldValue?: string | string[] //governs what selected value/s in the inital field will show the secondary field
  labelMacro?: string
  showInitialError?: boolean
  storeProp?: string
  secondaryStoreProp?: string
  defaultStoreProp?: string
  type: AccountFieldType
  class?: string
  rules?: RuleType[]
  mask?: 'phone' | 'dob' | 'pin'
  maxLength?: string | number
  hint?: string
  conditionals?: Conditional[]
  questions?: Question[]
  defaultValue?: string | boolean | number
  backgroundColor?: string
  options?: AccountFieldRadioOptions[]
  changeEvent?: string
  payIconHigh?: boolean
  colClass?: string
  fieldClass?: string
  dropdownOptions?: string[]
  fieldProps?: Record<string, string | boolean>
  trueValue?: string | number
  falseValue?: string | number
  configureDefault?: boolean
  src?: string
  element?: string
  maxNumberOfAnswers?: number
}

export interface PackagePiece {
  type: string
  class?: string
  largeClass?: string
  medClass?: string
  cols?: string
  headerTextType?: string
  rowClass?: string
  icon?: string
  isIconLarge?: string
  tooltipColor?: string
  tooltipIcon?: string
  isTooltipDark?: string
  showDotInPrice?: boolean
  singleButtonColor?: string
  buttonSameColorAsCard?: boolean
}

export interface Question {
  name: string
  type?: 'text' | 'date' | 'none'
  label?: string
  rules?: ('required' | 'email' | 'phone')[]
  mask?: 'phone' | 'dob' | 'pin'
}

export interface AccountFieldRadioOptions {
  label: string
  value: string
  labelMacro?: string
}

export interface AdditionalConfirmationMessage {
  conditions: (Conditional | Conditional[])[]
  message: string
  parseMessage?: boolean
}

export interface MaskConfigObject {
  name: string
  maskString: string
  tokensAndPatterns: Record<string, string>[]
}

export interface SocialMediaFooterItem {
  name: string
  link: string
  color?: string
  svgPath: string
}

export interface MaskObject {
  mask: string
  tokens: Record<string, Record<string, RegExp>>
}

export interface ConfirmationTextConfigItem {
  type: 'text' | 'shopperName'
  message?: string
}

const phoneMask = {
  mask: '(F##) ###-####',
  tokens: {
    '#': { pattern: /\d/ },
    F: { pattern: /[2-9]/ }
  }
}
const intPhoneMask = {
  mask: '+####################',
  tokens: {
    '#': { pattern: /\d/ },
    F: { pattern: /[0-9]/ }
  }
}
const dobMask = {
  mask: '##/##/####',
  tokens: {
    '#': { pattern: /\d/ }
  }
}

const pinMask = {
  mask: '####',
  tokens: {
    '#': { pattern: /\d/ }
  }
}

export const defaultMaskMap: Map<string, MaskObject> = new Map([
  ['phone', phoneMask],
  ['intPhone', intPhoneMask],
  ['dob', dobMask],
  ['pin', pinMask]
])
